import React, { useEffect, useMemo, useState } from 'react';
import {
  useLocale,
  useOnce,
  useParams,
  useQuiqChat,
  useStateView
} from '../../hooks';
import { accountView, paymentPlanView, settlementOffersView, shortLinkRedirectView } from '../../state/views';
import { getSettlementOffer } from '../../service/landingPage';
import { debugConsole, formatCurrency, getStandardAndLastPayments, mixpanel, numDaysInMonth, redirect } from '../../utils';
import { initialState } from '../../state/store';
import { useRollbar } from '@rollbar/react';

import { MIXPANEL_EVENTS, PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../../constants';
import { paths } from '../../routes';
import allMessages from './messages';

import { Link } from 'react-router-dom';
import {
  Datepicker,
  OfferHeader,
  OptionList,
  PaymentMethodForm,
  RepaymentDateDropdown,
  SettlementOfferOption,
  Spinner,
  Toggle,
  UnverifiedPhoneLogin,
  Variant,
  } from '../../components';

import { miniMirandaLink, datePick, settlementForm, settlementFormContent, toggle } from './styles.scss';
import Header from '../../components/Header';

const SettlementOfferLandingPage = () => {
  const minDate = new Date();

  const { messages, formatMessage } = useLocale(allMessages);
  const [ { accountholderFirstName } ] = useStateView(accountView);
  const [ { verified } ] = useStateView(shortLinkRedirectView);
  const { referenceNumber } = useParams();
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);
  const [ settlementOffers, setSettlementOffers ] = useStateView(settlementOffersView);
  const [ selectedPlan, setSelectedPlan ] = useState(PAYMENT_PLAN_TYPES.settleInFull);
  const rollbar = useRollbar();

  // load offers on component mount

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewSettlementOfferLandingPage));

  useQuiqChat(
    {
      firstName: accountholderFirstName,
      debtId: referenceNumber
    },
    verified
  );

  const startDateLimit = paymentPlan.paymentStartDateLimit || initialState.paymentPlan.paymentStartDateLimit;
  const numDays = useMemo(
    () => Math.max(numDaysInMonth() - minDate.getDate(), startDateLimit) - 1,
    [minDate.getDate(), startDateLimit]
  );
  const maxDate = useMemo(
    () => {
      const result = new Date(minDate);
      result.setDate(result.getDate() + numDays);
      return result;
    },
    [minDate, numDays]
  ); 
  useEffect(() => {
    if (settlementOffers.currentBalance) return;

    getSettlementOffer(referenceNumber)
      .then(response => {
        const { currentBalance, offerAmount, termsOffered } = response;
        const totalPaymentAmount = parseFloat(offerAmount);

        const plans = termsOffered
          .sort() // now the PIF plan variant will always be element 0
          .map(numPayments => {
            const {
              standardPaymentAmount,
              lastPaymentAmount
            } = getStandardAndLastPayments(totalPaymentAmount, numPayments);

            return {
              ...initialState.paymentPlan,
              planType: numPayments === 1
                ? PAYMENT_PLAN_TYPES.settleInFull
                : PAYMENT_PLAN_TYPES.settlementPlan,
              paymentFrequency: PAYMENT_FREQUENCIES.monthly,
              referenceNumber,
              totalPaymentAmount,
              numPayments,
              standardPaymentAmount,
              lastPaymentAmount
            };
          });

        // Take first plan as default to show
        const defaultPlan = plans[0];
        setSettlementOffers({ currentBalance, offerAmount, plans });
        setPaymentPlan({ ...defaultPlan, referenceNumber });
        mixpanel.track(
          defaultPlan.planType === PAYMENT_PLAN_TYPES.settleInFull
            ? MIXPANEL_EVENTS.selectSettleInFull
            : MIXPANEL_EVENTS.selectCreateSettlementPlan
        );
      }).catch(err => {
        rollbar.error('Error fetching settlement plan', err);
        redirect(paths.specialOfferError);
      });
  }, [ referenceNumber ]);

  const [ payInFullPlan, settlementPlans ] = useMemo(
    () => {
      const { plans } = settlementOffers;
      return [ plans?.[0], plans?.slice(1) ];
    },
    [ settlementOffers ]
  );

  const handleToggle = e => {
    const planType = e.target.value;
    const isSettleInFull = planType === PAYMENT_PLAN_TYPES.settleInFull;
    
    const plan = isSettleInFull
      ? payInFullPlan
      : {
        ...initialState.paymentPlan,
        planType
      };
    
    mixpanel.track(
      isSettleInFull
        ? MIXPANEL_EVENTS.selectSettleInFull
        : MIXPANEL_EVENTS.selectCreateSettlementPlan
    );

    setPaymentPlan(plan);
    setSelectedPlan(planType);
  };

  const handleSelectPlan = plan => {
    setPaymentPlan.update(plan);
    mixpanel.track(MIXPANEL_EVENTS.selectSettlementPlanTerms, { numPayments: plan.numPayments });
  };

  const handleSelectRepaymentDate = (paymentStartDate) => {
    setPaymentPlan.update({
      paymentStartDate: typeof paymentStartDate === 'object' && paymentStartDate !== null ? paymentStartDate.label : paymentStartDate
    });
  };

  const isPlan = selectedPlan === PAYMENT_PLAN_TYPES.settlementPlan;

  return <>
    <Header />
    <div className={settlementForm}>
      <UnverifiedPhoneLogin />
      <h1>{ messages.title }</h1>
      <h2>{ messages.subtitle }</h2>
      { settlementOffers.currentBalance
          ? <>
              <Toggle onChange={handleToggle} className={toggle}>
                <Toggle.Item value={PAYMENT_PLAN_TYPES.settleInFull} active>{ messages.payInFullLabel }</Toggle.Item>
                <Toggle.Item value={PAYMENT_PLAN_TYPES.settlementPlan}>{ messages.createPlanLabel }</Toggle.Item>
              </Toggle>
              <section>
                <OfferHeader
                  currentBalance={settlementOffers.currentBalance}
                  offerAmount={settlementOffers.offerAmount}
                  referenceNumber={referenceNumber} />
                <div className={settlementFormContent}>
                  { isPlan
                      ? <>
                          <h3>1. { messages.selectPayments }</h3>
                          <OptionList
                            name="selectedSettlementPlan"
                            onChange={handleSelectPlan}
                            options={settlementPlans}
                            optionComponent={SettlementOfferOption} />
                          <h3>2. { messages.selectMonthAndDay }</h3>
                          <div className={datePick}>
                          <Variant experiment='SOLP Date' variant='Datepicker'>
                            <Datepicker minDate={minDate} name={'paymentStartDate'} maxDate={maxDate} onChange={handleSelectRepaymentDate} />
                          </Variant>
                          <Variant experiment='SOLP Date' variant='Dropdown'>
                            <RepaymentDateDropdown
                              name="paymentStartDate"
                              onChange={handleSelectRepaymentDate}
                              compact />
                          </Variant>
                    </div>
                        </>
                      : <>
                          <h3>1. { messages.setupPayment }</h3>
                          <p>{ messages.paymentInstructions }*</p>
                          <div className={datePick}>
                          <Variant experiment='SOLP Date' variant='Datepicker'>
                          <Datepicker minDate={minDate} name={'paymentStartDate'} maxDate={maxDate} onChange={handleSelectRepaymentDate} />
                          </Variant>
                          <Variant experiment='SOLP Date' variant='Dropdown'>
                            <RepaymentDateDropdown
                                    name="paymentStartDate"
                                    onChange={handleSelectRepaymentDate}
                                    compact />
                          </Variant>
                    </div>
                        </> }
                  { paymentPlan.numPayments > 0 &&
                    <>
                      <h3>{ 2 + isPlan }. { messages.paymentMethod }</h3>
                      <PaymentMethodForm
                        accountId={referenceNumber}
                        onSubmit={() => redirect(paths.paymentConfirm)} /> 
                    </> }
                </div>
              </section>
            </>
          : <Spinner variant="large"/> }
      <p>{ formatMessage(messages.creditDisclosure, { receiptText: <b>{ messages.receiptText }</b> }) }</p>
      <p>{ messages.noObligation }</p>
      <p>{ messages.miniMiranda }</p>
      <p><Link data-mp-name="Know Your Rights" to={paths.disclosures} className={miniMirandaLink}>{ messages.knowYourRights }</Link></p>
    </div>;
  </>
};

export default SettlementOfferLandingPage;