const transformDateFunction = (a, b) => {
  return date => {
    const digitGroups = date.match(/\d+/g);
    return digitGroups?.length >= 3
      ? `${digitGroups[2].padStart(4, '0')}-${digitGroups[a].padStart(2, '0')}-${digitGroups[b].padStart(2, '0')}`
      : date;
  };
};

const defaultDateTransform = transformDateFunction(1, 0);

const dateOfBirth = {
  en: transformDateFunction(0, 1), // transform mm/dd/yyyy
  es: defaultDateTransform, // transform dd/mm/yyyy 
};

export {
  dateOfBirth
};
