import React, { useEffect, useState } from 'react';

import { useActivePaymentPlanStatus, useLocale, useQuiqChat, useStateView } from '../../hooks';
import { accountView, activePaymentPlansView, paymentPlanView } from '../../state/views';

import {
  Datepicker,
  DayOfWeekDropdown,
  PaymentMethodForm
} from '../../components';

import {
  debugConsole,
  generatePaymentScheduleFromPaymentPlan,
  redirect
} from '../../utils';

import allMessages from './messages';

import { getPaymentPlans } from '../../service/paymentPlan';

import { paymentPlanPage, dayDropdown, paymentDate, paymentMethodForm } from './styles.scss';
import { paths } from '../../routes';

import { PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../../constants';

const PaymentPlanSetupPage = () => {
  const [ { accountId: referenceNumber } ] = useStateView(accountView);
  const [ activePaymentPlans, setActivePaymentPlans ] = useStateView(activePaymentPlansView);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);
  const [ isStartDateScheduled, setIsStartDateScheduled ] = useState(false);
  
  const {
    planType,
    numPayments,
    paymentStartDate,
    paymentStartDateLimit,
    paymentFrequency
  } = paymentPlan;

  useQuiqChat({ debtId: referenceNumber });

  useEffect(() => {
    if (activePaymentPlans) return;

    getPaymentPlans(referenceNumber)
      .then(data => setActivePaymentPlans(data))
  }, [ activePaymentPlans, referenceNumber ]);

  const { canCreatePlan, promisePlan, isPromise } = useActivePaymentPlanStatus();

  useEffect(
    () => {
      if (isPromise) {
        // user is adding payment method to promise, transform the active
        // promise into a payment plan
        const promiseSchedule = promisePlan.paymentSchedule;
        const nextPaymentIndex = promiseSchedule
          .findIndex(({ paymentDate }) => paymentDate >= promisePlan.nextPaymentDate);
  
        const remainingPromiseSchedule = promiseSchedule
          .slice(nextPaymentIndex)
          .map(({ paymentAmount, paymentDate }) => ({ amount: paymentAmount, date: paymentDate }));
  
        setPaymentPlan.update({
          // promise payment intervals and amounts are arbitrary
          planType: null,
          paymentFrequency: null,
          paymentDayOfWeek: null,
          standardPaymentAmount: null,
          paymentStartDate: new Date(remainingPromiseSchedule[0].date),
          paymentSchedule: remainingPromiseSchedule,
          referenceNumber: referenceNumber,
          totalPaymentAmount: promisePlan.remainingBalance
        });
      } else {
        // generate payment schedule from user-defined payment plan
        const paymentSchedule = generatePaymentScheduleFromPaymentPlan(paymentPlan);
        setPaymentPlan.update({ paymentSchedule });
      }
    },
    [ isPromise, paymentPlan ]
  );
  
  const handleSelectDate = paymentStartDate => {
    // TODO track mixpanel event?
    setPaymentPlan.update({ paymentStartDate });
    setIsStartDateScheduled(true);
  };
    
  const handleSelectDayOfWeek = day => {
    // TODO track mixpanel event?
    setPaymentPlan.update({ paymentDayOfWeek: day.value });
  };

  const isValidDate = date => {
    return Math.floor((date - minDate) / 86400000) < paymentStartDateLimit;
  };

  const handleSubmitPaymentMethod = () => {
    // TODO mixpanel event
    redirect(paths.paymentConfirm);
  };

  const minDate = new Date();
  
  const { formatMessage, messages } = useLocale(allMessages);

  if (!canCreatePlan) {
    debugConsole.warn('User has an active payment plan or promise. Redirecting to homepage');
    redirect(paths.home);
  }

  const messageValues = {
    payments: numPayments !== 1,
    multipleDays: paymentStartDateLimit !== 1,
    days: paymentStartDateLimit
  };

  return <div className={paymentPlanPage}>
    <h1>{ formatMessage(messages.heading, messageValues) }</h1>
    { !isPromise && <section className={paymentDate}>
        { planType === PAYMENT_PLAN_TYPES.paymentPlan
          && paymentFrequency !== PAYMENT_FREQUENCIES.monthly
            ? <>
                <h3>{ messages.frequency }</h3>
                <DayOfWeekDropdown frequency={paymentFrequency} className={dayDropdown} onChange={handleSelectDayOfWeek} />
            </>
            : <>
              <h3>{ formatMessage(messages.paymentDatePrompt, messageValues) }</h3>
              <Datepicker
                minDate={minDate}
                initialDate={paymentStartDate ? new Date(paymentStartDate) : minDate}
                dateFilter={isValidDate}
                onChange={handleSelectDate} />
              <br/>
              <p>{ formatMessage(messages.paymentDateFootnote, messageValues) }</p>
            </>
        }
      </section> }
    { (isStartDateScheduled || isPromise || paymentFrequency !== PAYMENT_FREQUENCIES.monthly) && <PaymentMethodForm
        accountId={referenceNumber}
        onSubmit={handleSubmitPaymentMethod}
        className={paymentMethodForm} /> }
  </div>;
};

export default PaymentPlanSetupPage;
