export default {
  en: {
    heading: {
      confirmPayment: 'Confirm your Payment',
      confirmPaymentMethod: 'Confirm Your Payment Method',
      confirmPaymentPlan: 'Confirm Your Payment Plan'
    },
    submit: 'Authorize'
  },
  es: {
    heading: {
      confirmPayment: 'Confirme su pago',
      confirmPaymentMethod: 'Confirme su método de pago',
      confirmPaymentPlan: 'Confirme su plan de pago'
    },
    submit: 'Autorizar'
  }
};
