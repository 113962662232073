import React, { useEffect, useState } from 'react';
import { func, object, oneOfType, string } from 'prop-types';

import { useLocale, useStateView } from '../../hooks';
import { paymentMethodView } from '../../state/views';
import { getPaymentFormUrl } from '../../service/paymentMethod';
import { MIXPANEL_EVENTS, PAYMENT_METHODS } from '../../constants';

import { Spinner, Tab, TabContent } from '../../components';
import allMessages from './messages';
import { paymentMethodSelect } from './styles.scss';
import { cn, mixpanel } from '../../utils';

import { BankIcon, CardIcon } from '../icons';

const REPAY_URLS = process.env.REPAY_URLS.split(',').map(url => url.trim());

const iframeProps = {
  scrolling: 'no',
  width: '300'
};

const PaymentMethodForm = ({ accountId, className, onSubmit = () => {} }) => {
  const { messages } = useLocale(allMessages);
  const [ , setPaymentMethod ] = useStateView(paymentMethodView);
  const [ methodType, setMethodType ] = useState(null);
  const [ achFormUrl, setAchFormUrl ] = useState(undefined);
  const [ cardFormUrl, setCardFormUrl ] = useState(undefined);

  const formUrls = {
    ach: {
      url: achFormUrl,
      set: setAchFormUrl
    },
    card: {
      url: cardFormUrl,
      set: setCardFormUrl
    }
  };

  // attach listeners for iframe form submit

  const handlePaymentForm = ({ origin, data }) => {    
    const responseData = data?.paymentResponseData;

    if (!origin || REPAY_URLS.includes(origin) === false || !responseData) {
      // react-devtools will send a bunch of messages back through the iframe
      // when running a dev build, which we should ignore
      // additionally, Repay will send back a non-failure message with an origin but
      // no 'responseData' prop, these should also be ignored
      return;
    }

    if (!responseData || responseData.errors) {
      mixpanel.track(MIXPANEL_EVENTS.addPaymentMethodFailure, { errors: JSON.stringify(responseData.errors, null, 2) });
      return;
    }

    const {
      saved_payment_method: { token },
      payment_type_id: savedCardType,
      customer_id: referenceNumber,
      account_type: accountType,
      pn_ref: pnRef,
      result_details: { card_info: { type: cardType = '' } = {} } = {},
      result,
      last4,
      date,
      transit_number: routingNumber,
      name_on_check: nameOnAccount,
      name_on_card: nameOnCard
    } = responseData;

    const isCard = !!cardType;

    const paymentMethod = {
      consumerName: isCard ? nameOnCard : nameOnAccount,
      paymentSource: isCard ? savedCardType : accountType,
      type: isCard ? PAYMENT_METHODS.card : PAYMENT_METHODS.ach,
      cardType,
      routingNumber,
      pnRef,
      result,
      last4,
      token,
      date,
      referenceNumber
    };

    setPaymentMethod(paymentMethod);
    onSubmit(paymentMethod);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.addEventListener('message', handlePaymentForm);
    return () => window.removeEventListener('message', handlePaymentForm);
  }, []);

  // fetch payment form url when type is selected

  useEffect(() => {
    if (!methodType || formUrls[methodType].url) {
      return;
    }

    getPaymentFormUrl({ accountId, methodType })
      .then(res => formUrls[methodType].set(res[0].formUrl));
  }, [ accountId, methodType ]);

  return <div className={cn(className)}>
    <div className={paymentMethodSelect}>
      <Tab group="payment-method" id="card" onClick={() => setMethodType(PAYMENT_METHODS.card)}>
        <section>
          <CardIcon alt="credit and debit cards" />
          <p>{ messages.cardTabLabel }</p>
        </section>
      </Tab>
      <Tab group="payment-method" id="ach" onClick={() => setMethodType(PAYMENT_METHODS.ach)}>
        <section>
          <BankIcon alt="bank" />
          <p>{ messages.achTabLabel }</p>
        </section>
      </Tab>
    </div>
    <TabContent group="payment-method" id="card">
      { formUrls.card.url
        ? <section><iframe src={ formUrls.card.url } height="1000" {...iframeProps}></iframe></section>
        : <Spinner /> }
    </TabContent>
    <TabContent group="payment-method" id="ach">
      { formUrls.ach.url
        ? <section><iframe src={ formUrls.ach.url } height="1300" {...iframeProps}></iframe></section>
        : <Spinner variant="large"/> }
    </TabContent>
    <p>{ messages.emailDisclosure }</p>
  </div>;
};

PaymentMethodForm.propTypes = {
  className: oneOfType([ string, object ]),
  accountId: string.isRequired,
  onSubmit: func,
};

export default PaymentMethodForm;
