import React, { useEffect, useRef } from 'react';
import { func, number, string } from 'prop-types';
import { formatCurrency, parseCurrencyFloat } from '../../utils';
import { inputElement } from '../TextInput/styles.scss';

const CurrencyInput = ({
  symbol = '$',
  onChange = () => {},
  maxLength = 20,
  placeholder = formatCurrency(0),
  defaultValue,
  name,
  ...props
}) => {
  const hiddenRef = useRef(null);
  const displayRef = useRef(null);

  const updateValues = n => {
    const parsed = parseCurrencyFloat(n) || 0;
    const formatted = formatCurrency(parsed, symbol);
    displayRef.current.value = formatted;

    const hiddenInput = hiddenRef.current;
    hiddenInput.value = parsed;
    hiddenInput.onchange = onChange;
    hiddenInput.dispatchEvent(new Event('change'));
  };

  const handleFocus = e => {
    const parsed = parseCurrencyFloat(e.target.value) || 0;
    e.target.value = parsed;
  };

  const handleBlur = e => {
    const parsed = parseCurrencyFloat(e.target.value) || 0;
    const formatted = formatCurrency(parsed, symbol);
    e.target.value = formatted;
    setHiddenValue(parsed);
  };

  const handleChange = e => {
    const parsed = parseCurrencyFloat(e.target.value) || 0;
    hiddenRef.current.value = parsed;
    setHiddenValue(parsed);
  }

  const setHiddenValue = value => {
    const hiddenInput = hiddenRef.current;
    hiddenInput.value = value;
    hiddenInput.onchange = onChange;
    hiddenInput.dispatchEvent(new Event('change'));
  };

  useEffect(() => {
    updateValues(defaultValue);
  }, [ defaultValue ]);

  return <>
      <input
        type="hidden"
        ref={hiddenRef}
        name={name} />
      <input
        {...props}
        className={inputElement}
        ref={displayRef}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        maxLength={maxLength}
        type="text" />
    </>;
};

CurrencyInput.propTypes = {
  name: string.isRequired,
  symbol: string,
  onChange: func,
  maxLength: number,
  placeholder: string
};

export default CurrencyInput;
