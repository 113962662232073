export default {
  en: {
    paymentPlan: {
      heading: 'Congratulations',
      paymentSubheading: 'You created your payment plan successfully',
      paymentMethodSubheading: 'Your payment method has been registered'
    },
    singlePayment: {
      heading: 'Payment Submitted!',
      paymentSubheading: 'Congratulations, your payment is being processed.'
    },
    printReceipt: 'Print Receipt'
  },
  es: {
    paymentPlan: {
      heading: 'Felicitaciones',
      paymentSubheading: 'Ha creado su plan de pago correctamente',
      paymentMethodSubheading: 'Su método de pago se ha registrado'
    },
    singlePayment: {
      heading: '¡El pago se ha enviado!',
      paymentSubheading: 'Felicitaciones, su pago se está procesando.'
    },
    printReceipt: 'Imprimir recibo'
  }
};
