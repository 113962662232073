import React from 'react';
import { useLocale, useOnce, useStateView } from '../../hooks';
import { accountView } from '../../state/views';
import { mixpanel } from '../../utils';

import allMessages from './messages';
import { accountDetails } from './styles.scss';
import { MIXPANEL_EVENTS, PAYMENT_INFO_FORMAT } from '../../constants';

const AccountDetails = () => {
  const { formatDate, messages } = useLocale(allMessages);
  const [ account ] = useStateView(accountView);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewAccountDetailsPage));

  return <div className={accountDetails}>
    <div>{ messages.originalCreditor }</div>
    <div>{ account.clientName || messages.notAvailable }</div>
    <div>{ messages.originalAccountNumber }</div>
    <div>{ account.originalAccountNumber }</div>
    <div>{ messages.referenceNumber }</div>
    <div>{ account.accountId }</div>
    <div>{ messages.accountOpenedDate }</div>
    <div>{ formatDate(account.accountOpenedDate, PAYMENT_INFO_FORMAT) }</div>
  </div>;
};

export default AccountDetails;
