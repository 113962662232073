export default {
  en: {
    title: 'Payment plan to resolve your balance',
    goBack: 'Go Back',

    steps: {
      initial: {
        planIntro: 'Based on your current balance, we\'ve created this repayment plan for you:',
        instructions: 'If this plan works for you, click {acceptButtonText} to schedule your payments. If not, click {createButtonText} to offer a plan that works for you.',
        acceptDefaultPlan: 'Accept Payment Plan',
        createPlan: 'Create My Own Plan',
        payInFull: 'Pay Full Balance',
      },
      createPlan: {
        title: 'Create Your Payment Plan',
        instructions: 'Enter the monthly amount you wish to pay below, then click {buttonText}.',
        submit: 'Make Offer',
        proposedAmountTooHigh: 'Proposed amount was too high. Please enter a lower amount.',
        proposedAmountTooLow: 'Proposed amount was too low. Please enter a higher amount.',
      },
      paymentDate: {
        title: 'Congratulations!',
        instructions: 'Now pick the date of your first payment:',
        submit: 'Select Payment Method'
      },
      paymentMethod: {
        title: 'Select Your Payment Method'
      }
    }
  },
  es: {
    title: 'Plan de pago para resolver su saldo',
    goBack: 'Volver',

    steps: {
      initial: {
        planIntro: 'En función de su saldo actual, hemos creado este plan de pago para usted:',
        instructions: 'Si este plan le funciona, haga clic en Aceptar el plan de pagos para programar sus pagos. Si no es así, haga clic en Crear mi propio plan a fin de ofrecer un plan que funcione para usted.',
        acceptDefaultPlan: 'Aceptar el plan de pago',
        createPlan: 'Crear mi propio plan',
        payInFull: 'Pague en su totalidad',
      },
      createPlan: {
        title: 'Cree su plan de pago',
        instructions: 'Ingrese el monto mensual que desea pagar a continuación, haga clic en {buttonText}.',
        submit: 'Volver a calcular',
        proposedAmountTooHigh: 'El monto propuesto que ha ingresado es demasiado alto. Ingrese un monto de pago más bajo.',
        proposedAmountTooLow: 'El monto propuesto que ha ingresado es demasiado bajo. Ingrese un monto de pago más alto.',
      },
      paymentDate: {
        title: '¡Felicitaciones!',
        instructions: 'Seleccione la fecha de su pago:',
        submit: 'Seleccione el método de pago'
      },
      paymentMethod: {
        title: 'Seleccione el método de pago'
      }
    }
  }
};
