export default {
  en: {
    title: 'Special Offer To Resolve Your Balance',
    subtitle: 'Select your payment method of preference',
    payInFullLabel: 'One Payment',
    createPlanLabel: 'Create a Plan',

    setupPayment: 'Set up your payment',
    paymentInstructions: 'When would you like your payment to be scheduled?',
    paymentLabel: 'Select a date',
    paymentFootnote: 'You can select a date up to {latency} day{multipleDays?s:} from today.',

    selectPayments: 'Select your payments',
    selectMonthAndDay: 'Select month & day of payments',
    paymentDates: 'Payment Dates',
    paymentDate: 'Payment Date',
    amount: 'Amount',

    paymentMethod: 'Select your payment method',
    noObligation: 'Spring Oaks Capital, LLC is not obligated to renew this offer.'
  },
  es: {
    title: 'Oferta especial para resolver su saldo',
    subtitle: 'Seleccione su método de pago preferido',
    payInFullLabel: 'Un pago',
    createPlanLabel: 'Cree un plan',

    setupPayment: 'Seleccione sus pagos',
    paymentInstructions: '¿Cuándo desea que se programe su pago?',
    paymentLabel: 'Seleccione una fecha',
    paymentFootnote: 'Puede seleccionar una fecha hasta {latency} día{multipleDays?s:} a partir de hoy.',
    
    selectPayments: 'Seleccione sus pagos',
    selectMonthAndDay: 'Seleccione el mes y día de los pagos',
    paymentDates: 'Fechas de pago',
    paymentDate: 'Fecha de pago',
    amount: 'Monto',
    
    paymentMethod: 'Seleccione su método de pago',
    noObligation: 'Spring Oaks Capital, LLC no está obligada a renovar esta oferta.'
  }
};
