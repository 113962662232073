import React, { useEffect, useState } from 'react';

import { useActivePaymentPlanStatus, useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import { accountView, paymentMethodView, paymentPlanView, shortLinkRedirectView } from '../../state/views';

import { saveProofOfAuth } from '../../service/paymentMethod';
import { createPaymentPlan, createPaymentPlanSchedule } from '../../service/paymentPlan';

import { debugConsole, logout, mixpanel, redirect, replaceUrl, weekdayString } from '../../utils';
import allMessages from './messages';

import { paths } from '../../routes';
import { MIXPANEL_EVENTS, PAYMENT_METHODS, PAYMENT_PLAN_TYPES } from '../../constants';

import { Button, PaymentInfo } from '../../components';
import { generatePaymentScheduleFromPaymentPlan } from '../../utils/date';

import { paymentConfirm } from './styles.scss';

const PaymentConfirmPage = () => {
  const [ account ] = useStateView(accountView);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);
  const [ paymentMethod ] = useStateView(paymentMethodView);
  const [ shortLinkRedirect ] = useStateView(shortLinkRedirectView);
  const { formatMessage, messages } = useLocale(allMessages);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const { planType } = paymentPlan;

  const accountId = shortLinkRedirect.referenceNumber || account.accountId;

  useQuiqChat({ debtId: accountId });

  if (!accountId || !paymentMethod.type) {
    // this page doesn't require authentication (due to the splash pages using it) but
    // we must only allow users with an account and payment method to be here
    logout(); // even if logged out already, clear everything and redirect to login
  }

  const { canCreatePlan, promisePlan, isPromise } = useActivePaymentPlanStatus();

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewAuthorizationPaymentPage, { option: paymentPlan.planType }));

  useEffect(() => {
    if (isPromise && planType !== PAYMENT_PLAN_TYPES.paymentPartial) {
      // user is adding payment method to promise, transform the active
      // promise into a payment plan
      const promiseSchedule = promisePlan.paymentSchedule;
      const nextPaymentIndex = promiseSchedule
        .findIndex(({ paymentDate }) => paymentDate >= promisePlan.nextPaymentDate);

      const remainingPromiseSchedule = promiseSchedule
        .slice(nextPaymentIndex)
        .map(({ paymentAmount, paymentDate }) => ({ amount: paymentAmount, date: paymentDate }));

      setPaymentPlan.update({
        // promise payment intervals and amounts are arbitrary
        planType: null,
        paymentFrequency: null,
        paymentDayOfWeek: null,
        standardPaymentAmount: null,
        paymentStartDate: new Date(remainingPromiseSchedule[0].date),
        paymentSchedule: remainingPromiseSchedule,
        referenceNumber: account.referenceNumber,
        totalPaymentAmount: promisePlan.remainingBalance
      });
    } else {
      // generate payment schedule from user-defined payment plan
      const paymentSchedule = generatePaymentScheduleFromPaymentPlan(paymentPlan);
      setPaymentPlan.update({ paymentSchedule });
    }
  }, [ isPromise, paymentPlan ]);

  if (!canCreatePlan) {
    debugConsole.warn('User has an active payment plan. Redirecting to homepage');
    redirect(paths.home);
  }

  const submitPayment = async () => {
    if (isSubmitting) return;

    debugConsole.log('submitting payment');
    setIsSubmitting(true);

    if (!(shortLinkRedirect?.referenceNumber)) {
      // raise "Click (Pay|Create Plan)" events for main portal conversions
      mixpanel.track(planType === PAYMENT_PLAN_TYPES.paymentPlan
        ? MIXPANEL_EVENTS.clickPaymentPlan
        : MIXPANEL_EVENTS.clickPay);
    }
    
    // record proof of auth
    const { paymentSchedule } = paymentPlan;

    if (paymentMethod.type === PAYMENT_METHODS.ach) {
      // probably don't need to await this, if not then this fn doesn't
      // need to be async
      await saveProofOfAuth({
        accountId,
        consumerName: paymentMethod.consumerName,
        routeNumber: paymentMethod.routingNumber,
        bankAcctLast4: paymentMethod.last4,
        accountType: paymentMethod.paymentSource,
        paymentSchedules: paymentSchedule.map(({ date, amount }) => ({ paymentDate: date, currencyValue: amount })),
      });
    }

    // make payment schedule request

    const request = {
      accountId,
      planDetails: {
        paymentSchedule,
        paymentProcessorReturn: {
          customer_id: accountId,
          methodType: paymentMethod.type,
          date: paymentMethod.date,
          pn_ref: paymentMethod.pnRef,
          result: paymentMethod.result,
          token: paymentMethod.token,
          card_type: paymentMethod.cardType,
          payment_source: paymentMethod.paymentSource,
          payment_last4: paymentMethod.last4
        }
      }
    };

    let createPlanRequest = createPaymentPlanSchedule;

    switch (planType) {
      case PAYMENT_PLAN_TYPES.paymentPartial:
        const firstPayment = request.planDetails.paymentSchedule[0];
        const startDate = new Date(firstPayment.date);
        startDate.setUTCHours(11);

        delete request.planDetails.paymentSchedule;
        request.planDetails = {
          ...request.planDetails,
          paymentAmount: firstPayment.amount,
          paymentDayOfMonth: null,
          paymentDayOfWeek: null,
          planScheduleFrequency: 1,
          planStartDate: startDate.toISOString(),
          planTerm: 1,
          planType: 'partial'
        };

        createPlanRequest = createPaymentPlan;
        break;
      case PAYMENT_PLAN_TYPES.payInFull:
        request.planDetails.planType = 'PIF';
        break;
      case PAYMENT_PLAN_TYPES.settleInFull:
        request.planDetails.planType = 'SIF';
        break;
      case PAYMENT_PLAN_TYPES.settlementPlan:
        request.planDetails.planType = 'settlementSchedule';
        break;
      default:
        request.planDetails.planType = 'schedule';
        break;
    }

    createPlanRequest(request)
      .then(() => {
        // replace history state so user can't hit back button to return here
        replaceUrl(paths.paymentSuccess);
      }).catch(err => {
        debugConsole.error('Payment plan create error', err);
        replaceUrl(shortLinkRedirect?.referenceNumber ? paths.specialOfferError : paths.accountError);
      }).finally(() => setIsSubmitting(false));
  };

  let heading;

  if (!planType) {
    heading = messages.heading.confirmPaymentMethod;
  } else if (planType === PAYMENT_PLAN_TYPES.paymentPlan || planType === PAYMENT_PLAN_TYPES.settlementPlan) {
    heading = messages.heading.confirmPaymentPlan;
  } else {
    heading = messages.heading.confirmPayment;
  }

  return <div className={paymentConfirm}>
    <h1>{ heading }</h1>
    <section><PaymentInfo /></section>
    <p>{ messages.authAgreement[paymentMethod.type] }</p>
    <p>{ formatMessage(messages.authAgreement.cta, { buttonText: messages.continue }) }</p>
    <p>{ messages.authAgreement.print }</p>
    <Button data-action="confirm payment" onClick={submitPayment} loading={isSubmitting} disabled={isSubmitting} variant={[ 'full', 'large' ]}>{ messages.continue }</Button>
  </div>;
};

export default PaymentConfirmPage;
