import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useActivePaymentPlanStatus, useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import {
  accountView,
  activePaymentPlansView,
  paymentPlanView,
  selectedReferenceNumberView
} from '../../state/views';

import {
  debugConsole,
  formatCurrency,
  mixpanel,
  redirect,
  templateString
} from '../../utils';
import allMessages from './messages';

import { getPaymentPlans } from '../../service/paymentPlan';

import {
  AccountDetails,
  Button,
  Expander,
  Modal,
  Notice,
  PaymentHistory,
  ProgressBar,
  Spinner
} from '../../components';

import {
  buttonRow,
  creditNotice,
  inlineCta,
  paidAmount,
  paymentMethodPrompt,
  viewPaymentPlans,
  homePage
} from './styles.scss';
import { paths } from '../../routes';
import { MIXPANEL_EVENTS, PAYMENT_PLAN_TYPES } from '../../constants';

const HomePage = () => {
  const [ account ] = useStateView(accountView);
  const [ activePaymentPlans, setActivePaymentPlans ] = useStateView(activePaymentPlansView);
  const [ , setPaymentPlan, clearPaymentPlan ] = useStateView(paymentPlanView);
  const [ selectedReferenceNumber ] = useStateView(selectedReferenceNumberView);
  const { formatMessage, messages } = useLocale(allMessages);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewAccountDetailsPage));

  useQuiqChat({ debtId: account.accountId });

  const telLink = useMemo(
    () => <Link to={`tel:+1${messages.paymentContactNumber.replace(/[^\d]/g, '')}`}>{messages.paymentContactNumber}</Link>,
    [ messages.paymentContactNumber ]
  );

  const [ isCreditNoticeVisible, setCreditNoticeVisible ] = useState(false);

  const {
    accountholderFirstName: firstName,
    originalBalance,
    remainingBalance,
    creditReportAddDate,
    creditReportDeleteDate,
    isCreditReportingEnabled
  } = account;

  const showCreditNotice = isCreditReportingEnabled && creditReportAddDate && !creditReportDeleteDate;

  const alreadyPaid = originalBalance - remainingBalance;

  useEffect(() => {
    getPaymentPlans(account.accountId)
      .then(data => {
        mixpanel.set({ hasPaymentPlans: data?.paymentPlans?.length > 0 });
        setActivePaymentPlans(data);
      })
  }, [selectedReferenceNumber]);

  const {
    canCreatePlan,
    hasPromise,
    hasNoActivePlans,
    hasNonPromisePlans
  } = useActivePaymentPlanStatus();

  debugConsole.log(
    'Payment plan(s) status',
    {
      hasPromise,
      hasNoActivePlans,
      hasNonPromisePlans,
      canCreatePlan
    }
  );

  const handlePayInFull = () => {
    mixpanel.track(MIXPANEL_EVENTS.clickPayInFull);

    clearPaymentPlan();
    setPaymentPlan.update({
      planType: PAYMENT_PLAN_TYPES.payInFull,
      numPayments: 1,
      standardPaymentAmount: remainingBalance,
      totalPaymentAmount: remainingBalance
    });
    redirect(paths.paymentPlanSetup);
  };

  // TODO remove when everyone moves onto standard link events
  const handleClickRepaymentOptions = () => {
    mixpanel.track(MIXPANEL_EVENTS.clickMorePaymentOptions);
  };

  const handleClickAddPaymentMethod = () => {
    mixpanel.track(MIXPANEL_EVENTS.clickAddPaymentMethodToPromise);
  };

  return <div className={homePage}>
    <h1>{ formatMessage(messages.welcome, { firstName }) }</h1>
    { activePaymentPlans
        ? (remainingBalance > 0 && <>
          <section>
            <h3>{ messages.remainingBalance } <strong>{ formatCurrency(remainingBalance) }</strong></h3>
            { canCreatePlan && <div className={inlineCta}>
              { messages.cta.prompt }
              <div className={buttonRow}>
                <Button onClick={handlePayInFull} data-action="select pay in full" href={paths.paymentPlanSetup} variant="alternate">{ messages.cta.payInFull }</Button>
                <Button onClick={handleClickRepaymentOptions} data-action="select more repayment options" href={paths.paymentPlan}>{ messages.cta.morePaymentOptions }</Button>
              </div>
            </div> }
            <div className={paidAmount}>{ messages.alreadyPaid } <strong>{ formatCurrency(alreadyPaid) }</strong></div>
            <ProgressBar value={alreadyPaid} max={originalBalance}/>
            { hasPromise && <div className={paymentMethodPrompt}>
                <p>{ messages.addPaymentMethodPrompt }</p>
                <Button onClick={ handleClickAddPaymentMethod } href={paths.paymentPlanSetup}>{ messages.addPaymentMethodCTA }</Button>
              </div> }
            { !hasNoActivePlans && hasNonPromisePlans && <div className={viewPaymentPlans}>
                <Button href={paths.paymentPlan}>{ messages.viewPaymentPlans }</Button>
              </div> }
            { showCreditNotice && <>
                <a className={creditNotice} onClick={() => setCreditNoticeVisible(true)}>{ messages.creditNoticeHeading }</a>
                <Modal visible={isCreditNoticeVisible} onClose={() => setCreditNoticeVisible(false)}>
                  <h3>{ messages.creditNoticeHeading }</h3>
                  <p>{ messages.creditNotice }</p>
                  <Button type="submit" variant="full" onClick={() => setCreditNoticeVisible(false)}>{ messages.gotIt }</Button>
                </Modal>
              </> }
          </section>
        </>)
        : <Spinner variant="large"/> }
    <section>
      <Expander label={messages.accountDetails}>
        <AccountDetails/>
      </Expander>
    </section>
    <PaymentHistory length={2}/>
    <Notice>{ templateString(messages.paymentContactNotice, { telLink }) }</Notice>
  </div>
};

export default HomePage;
