export const PAYMENT_METHODS = {
  card: 'card',
  ach: 'ach'
};

export const PAYMENT_STATUS = {
  success: 'success',
  error: 'error'
};

export const PAYMENT_PLAN_TYPES = {
  payInFull: 'payInFull',
  paymentPartial: 'paymentPartial',
  paymentPlan: 'paymentPlan',
  settleInFull: 'settleInFull',
  settlementPlan: 'settlementPlan'
};

export const PAYMENT_PLAN_MIXPANEL_TYPES = {
  specialPaymentPlan: 'specialPaymentPlan',
  specialPayInFull: 'specialPayInFull'
}

export const PAYMENT_FREQUENCIES = {
  weekly: 'weekly',
  everyTwoWeeks: 'everyTwoWeeks',
  monthly: 'monthly'
};
