export default {
  en: {
    paymentFrequencies: {
      weekly: 'Weekly',
      everyTwoWeeks: 'Biweekly',
      monthly: 'Monthly',
      biweekly: 'Biweekly'
    },
    payments: 'payment{multiplePayments?s:}',
    paymentsOf: '{finalPayment?plus :}{numPayments?numPayments:1} {finalPayment?final :}payment{multiplePayments?s:} of',
    of: 'of',
    every: 'every',
    everyOther: 'every other',
    notAvailable: 'Not available at this time',
    continue: 'Continue',
    viewAll: 'View all',
    remainingBalance: 'Remaining Balance',
    alreadyPaid: 'You already paid',
    authAgreement: {
      ach: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring ACH/electronic debit to my
      account in the amount(s) and date(s) set forth above. I agree that ACH transactions I authorize comply with all
      applicable law. Furthermore, I assert that I am the owner or an authorized signer of the bank account provided. If
      you wish to rescind this authorization or cancel payment arrangements, you may write Spring Oaks Capital, LLC at
      P.O. Box 1216, Chesapeake, VA 23327-1216 or call (866) 281-3065 during our normal business hours of Monday through
      Thursday 8am-11pm ET, Friday 8am-8pm ET.`,
      card: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring credit/debit card charge to
      my account set forth above in the amount(s) and date(s) set forth above. I agree that credit/debit card transactions
      I authorize comply with all applicable law. For recurring payment arrangements, I understand that this authorization
      will remain in full force and effect until I notify Spring Oaks Capital, LLC in writing or by phone that I wish to
      revoke this authorization. If you wish to rescind this authorization or cancel payment arrangements, you may write
      Spring Oaks Capital, LLC at P.O. Box 1216, Chesapeake, VA 23327-1216 or call (866) 281-3065 during our normal hours
      of Monday through Thursday 8am-11pm ET, Friday 8am-8pm ET.`,
      cta: `To complete the payment process, click the “{buttonText}“ button.`,
      print: `It is recommended that you print a copy of this authorization and maintain it for your records.`
    },
    miniMiranda: 'This is a communication from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.',
    knowYourRights: 'Know Your Rights',
    backToHome: 'Back to Home',
    backToWebsite: 'Back to website',
    goBack: 'Go Back',
    contactUs: 'Contact Us',
    contactNumber: '866-539-7525',
    tryAgain: 'Try Again',
    gotIt: 'Got it!',
    notice: 'Notice',
    defaultCreditorName: 'Spring Oaks Capital, LLC',
    creditDisclosure: 'If Spring Oaks Capital, LLC is reporting this account to a credit reporting agency, {receiptText} of the final payment resolving the account, Spring Oaks Capital, LLC will request deletion of its tradeline from your credit report.',
    receiptText: 'within 30 days of receipt',
    springOaksCapital: 'Spring Oaks Capital, LLC'
  },
  es: {
    paymentFrequencies: {
      weekly: 'Semanalmente',
      everyTwoWeeks: 'Cada 2 semanas',
      monthly: 'Mensualmente',
      biweekly: 'Bisemanalmente'
    },
    payments: 'pago{multiplePayments?s:}',
    paymentsOf: '{finalPayment?y :}{numPayments?numPayments:1} pago{multiplePayments?s:} de',
    of: 'de',
    every: 'cada',
    everyOther: 'cada dos',
    notAvailable: 'No está disponible en este momento',
    continue: 'Continuar',
    viewAll: 'Ver todo',
    remainingBalance: 'Saldo restante',
    alreadyPaid: 'Ya pagó',
    authAgreement: {
      ach: `Autorizo a Spring Oaks Capital, LLC en lo sucesivo a iniciar un débito electrónico/de la ACH único o recurrente en mi cuenta por
      el/los monto(s) y en la(s)fecha(s) que se establecen arriba. Acepto que las transacciones de la ACH que autorizo cumplen con todas las
      leyes aplicables. Además, afirmo que soy el titular o firmante autorizado de la cuenta bancaria proporcionada. Si desea rescindir esta
      autorización o cancelar los acuerdos de pago, puede escribir a Spring Oaks Capital, LLC a P.O. Box 1216, Chesapeake, VA 23327-1216 o
      llamar al (866) 281-3065 durante nuestro horario de atención normal de lunes a jueves de 8:00 a.m. a 11:00 p.m. hora del este, y los
      viernes de 8:00 a.m. a 8:00 p.m. hora del este.`,
      card: `Autorizo a Spring Oaks Capital, LLC en lo sucesivo a iniciar un cargo único o recurrente de tarjeta de crédito/débito en mi cuenta
      establecida anteriormente por el/los monto(s) y en la(s) fecha(s) que se establecen arriba. Acepto que las transacciones con tarjeta de
      crédito/débito que autorizo cumplen con todas las leyes aplicables. Para acuerdos de pago recurrentes, entiendo que esta autorización
      permanecerá en pleno vigor y efecto hasta que notifique a Spring Oaks Capital, LLC por escrito o por teléfono que deseo revocar esta
      autorización. Si desea rescindir esta autorización ocancelar los acuerdos de pago, puede escribir a Spring Oaks Capital, LLC a P.O.
      Box 1216, Chesapeake, VA 23327-1216 o llamar al (866) 281-3065 durante nuestro horario de atenciónnormal de lunes a jueves de 8:00 a.m.
      a 11:00 p.m. hora del este, y los viernes de 8:00 a.m. a 8:00 p.m. hora del este.`,
      cta: 'Para completar el proceso de pago, haga clic en el botón “{buttonText}“.',
      print: 'Se recomienda que imprima una copia de esta autorización y la conserve para sus registros.'
    },
    miniMiranda: 'Esta es una comunicación de un cobrador de deudas. Este es un intento de cobrar una deuda y cualquier información obtenida se utilizará para ese propósito.',
    knowYourRights: 'Conozca sus derechos',
    backToHome: 'Volver al Inicio',
    backToWebsite: 'Volver al sitio web',
    goBack: 'Volver',
    contactUs: 'Contáctenos',
    contactNumber: '866-539-7525',
    tryAgain: 'Intente de nuevo',
    gotIt: '¡Comprendo!',
    notice: 'Aviso',
    defaultCreditorName: 'Spring Oaks Capital, LLC',
    creditDisclosure: 'Si Spring Oaks Capital, LLC reporta esta cuenta a una agencia de informes de crédito {receiptText} final que resuelve la cuenta, Spring Oaks Capital, LLC, solicitará la eliminación de la línea de crédito de su informe de crédito.',
    receiptText: 'en el transcurso de 30 días a partir de la recepción del pago',
    springOaksCapital: 'Spring Oaks Capital, LLC'
  }
};
