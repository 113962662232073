import React from 'react';

import { useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import { defaultSpecialPlanView, paymentMethodView, paymentPlanView, settlementOffersView } from '../../state/views';

import { MIXPANEL_EVENTS, PAYMENT_PLAN_TYPES } from '../../constants';
import allMessages from './messages';

import { Button, GoBack, PaymentInfo } from '../../components';
import { PrintIcon } from '../../components/icons';

import { paymentSuccess, printButton } from './styles.scss';
import { logout, mixpanel } from '../../utils';
import { paths } from '../../routes';

const PaymentSuccessPage = () => {
  const [ { planType, paymentSchedule, referenceNumber } ] = useStateView(paymentPlanView);
  const [ { currentBalance: settlementAmount } ] = useStateView(settlementOffersView);
  const [ { planType: specialPlanType } ] = useStateView(defaultSpecialPlanView);
  const [ { type } ] = useStateView(paymentMethodView);

  const { locale, messages } = useLocale(allMessages);

  if (!type) {
    logout();
  }

  useQuiqChat({ debtId: referenceNumber });

  const headings = !planType || planType === PAYMENT_PLAN_TYPES.paymentPlan
    ? messages.paymentPlan
    : messages.singlePayment;

  let subheading = planType
    ? headings.paymentSubheading
    : headings.paymentMethodSubheading;

  useOnce(() => {
    const isSettlementOffer = !!settlementAmount;
    const isSpecialOffer = !!specialPlanType;
    const isSinglePayment = paymentSchedule?.length === 1;

    let event;

    if (!planType) {
      // add payment method to promise conversion
      event = MIXPANEL_EVENTS.viewAddPaymentMethodSuccessPage;
    } else if (isSettlementOffer) {
      // settlement splash page conversion
      event = isSinglePayment
        ? MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePIF
        : MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePlan;
    } else if (isSpecialOffer) {
      // payment plan splash page conversion
      event = isSinglePayment
        ? MIXPANEL_EVENTS.viewSpecialOfferSuccessPagePIF
        : MIXPANEL_EVENTS.viewSpecialOfferSuccessPagePlan;
    } else {
      // generic main portal payment conversion
      event = MIXPANEL_EVENTS.viewPaymentConfirmationPage;
    }

    mixpanel.track(event, { send_immediately: true });
  });

  const handlePrint = () => {
    // TODO mixpanel event 
    window?.print();
  };

  return <div className={paymentSuccess}>
    <GoBack href={`${process.env.SOC_WEB_URL}?lang=${locale}`}>{ messages.backToHome }</GoBack>
    <h1>{ headings.heading }</h1>
    <h2>{ subheading }</h2>
    <section><PaymentInfo /></section>
    <p>{ messages.authAgreement[type] }</p>
    <p>{ messages.authAgreement.print }</p>
    <p>
      <Button
        className={printButton}
        onClick={handlePrint}
        variant={['alternate', 'outline']}>
          <PrintIcon/>
          <div>{ messages.printReceipt }</div>
      </Button>
    </p>
  </div>;
};

export default PaymentSuccessPage;
