export default {
  en: {
    title: 'Payments',
    description: 'Choose the best payment option by considering payment \namount, frequency, and how it fits into your budget.',
    empty: 'You don\’t have any payment plans',
    installments: 'Installments',
    nextPayments: 'Next Payments',
    addPaymentMethodCTA: 'Add Payment Method',
    payInFull: 'Pay in Full',
    createPlan: 'Create a Payment Plan',
    partialPayment: 'Make a Partial Plan',
    payInFullSubtitle: 'Pay your entire balance at once \nwith a single payment.',
    createPlanSubtitle: 'Customize a payment schedule \nthat works for you.',
    partialPaymentSubtitle: 'Pay a portion of your balance now \nand address the remainder later.'
  },
  es: {
    title: 'Pagos',
    description: 'Elija la mejor opción de pago al considerar el monto del pago, la \nfrecuencia y cómo se ajusta a tu presupuesto.',
    empty: 'No tiene ningún plan de pago',
    installments: 'Cuotas',
    nextPayments: 'Próximos pagos',
    addPaymentMethodCTA: 'Agregue un método de pago',
    payInFull: 'Pague en su totalidad',
    createPlan: 'Cree un plan de pagos',
    partialPayment: 'Cree un plan parcial',
    payInFullSubtitle: 'Pague el total de su saldo de una vez \ncon un solo pago.',
    createPlanSubtitle: 'Personalice un cronograma de pagos \nque funcione para usted.',
    partialPaymentSubtitle: 'Pague una parte de su saldo ahora \ny aborde el resto más adelante.'
  }
};
