// extracted by mini-css-extract-plugin
var _1 = "YKzEfVxg028eVymWBHtq";
var _2 = "NszAcQpIqEqYWq55VZT3";
var _3 = "L_1u7j9ZL58bG5Ai_dDv";
var _4 = "o3XT5osOTtUV7XEX_EJE";
var _5 = "LmKasTWfrUwvgNBb3Eq4";
var _6 = "LCfijeWiO_cb9kHMGEkM";
var _7 = "x3zakns0fSIFhuARiLrc";
var _8 = "oLO0TEQMZsYzuwRQl0HY";
export { _1 as "inputArea", _2 as "inputError", _3 as "inputWithButton", _4 as "miniMirandaLink", _5 as "paymentSummary", _6 as "pplp", _7 as "pplpContent", _8 as "stepButtons" }
