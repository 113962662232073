import React, { useEffect, useMemo, useState } from 'react';
import { arrayOf, number, oneOf } from 'prop-types';
import { useLocale, useStateView } from '../../hooks';
import { useRollbar } from '@rollbar/react';
import { accountView, paymentHistoryView } from '../../state/views';
import { getPaymentHistory } from '../../service/paymentPlan';
import { formatCurrency } from '../../utils';
import allMessages from './messages';
import { paymentHistory } from './styles.scss';
import { ACCOUNT_DATE_FORMAT } from '../../constants';
import Button from '../Button';
import { paths } from '../../routes';
import EmptyPage from '../EmptyPage/index.js';
import Spinner from '../Spinner';

const validColumns = [
  'amount',
  'date',
  'paymentMethod',
  'statusCode',
  'transactionId'
];

const defaultColumns = [
  'date',
  'paymentMethod',
  'amount',
  'statusCode'
];

const PaymentHistory = ({ length = 0, columns = defaultColumns, ...props }) => {
  const [{ accountId: referenceNumber }] = useStateView(accountView);
  const [history, setHistory] = useStateView(paymentHistoryView);
  const [isLoading, setIsLoading] = useState(false);

  const { formatDate, messages } = useLocale(allMessages);

  const formats = {
    date: d => formatDate(d, ACCOUNT_DATE_FORMAT),
    amount: formatCurrency,
    paymentMethod: (method) => messages.paymentMethod[method] || method,
    statusCode: (status) => messages.statusCode[status]
  };

  const rollbar = useRollbar();

  useEffect(() => {
    if (history) return;
    setIsLoading(true);

    getPaymentHistory(referenceNumber)
      .then(({ payments }) => {
        setHistory(payments);
      })
      .catch(err => {
        // debugging info
        rollbar.error('Failed to retrieve payment history by reference number', err, { referenceNumber });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [referenceNumber]);

  return isLoading ? (
    <Spinner />
  ) : (
    history?.length > 0 ? (
      <section className={paymentHistory} {...props}>
        <h4>{messages.header}</h4>
        <table>
          <thead>
            <tr>
              {columns.map((name, i) => (
                <th key={`ph-th-${i}`}>{messages.columns[name]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {history.slice(0, length || history.length).map((payment, i) => (
              <tr key={`ph-tr-${payment.referenceNumber}-${i}`}>
                {columns.map((name, j) => (
                  <td
                    key={`ph-td-${j}`}
                    data-column-name={name}
                    data-column-value={payment[name]}
                  >
                    {name in formats ? formats[name](payment[name]) : messages[name][payment[name]]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {length !== 0 && (
            <tfoot>
              <tr>
                <td colSpan={columns.length}>
                  <Button variant={['outline', 'alternate', 'full']} href={paths.paymentHistory}>
                    {messages.viewAll}
                  </Button>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </section>
    ) : (
        <EmptyPage PageTitle={messages.empty} />
    )
  );
};

PaymentHistory.propTypes = {
  length: number,
  columns: arrayOf(oneOf(validColumns))
};

export default PaymentHistory;
