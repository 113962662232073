import React from 'react';
import { useLocale } from '../../hooks';
import { cn } from '../../utils';
import { notice, noticeContent } from './styles.scss';
import infoIconSrc from '../../static/images/icons/infoIcon.svg';

const Notice = ({ className, children }) => {
  const { messages } = useLocale();

  return <section className={cn(notice, className)}>
      <img src={infoIconSrc} alt={messages.notice} />
      <div className={noticeContent}>{ children }</div>
    </section>;
};

export default Notice;
