import React from 'react';
import Dropdown from '../Dropdown';
import { useStateView } from '../../hooks';
import { referenceNumbersView, selectedReferenceNumberView } from '../../state/views';

import { accountSelect } from './styles.scss';

const AccountSelect = () => {
  const [ referenceNumbers ] = useStateView(referenceNumbersView);
  const [ selectedReferenceNumber, setSelectedReferenceNumber ] = useStateView(selectedReferenceNumberView);

  if (!selectedReferenceNumber || referenceNumbers.length === 0) return null;

  const items = referenceNumbers.map(item => ({
    label: `#${item.toString()}`,
    value: item,
    isDefault: item === selectedReferenceNumber
  }));
  
  const handleChange = ({value}) => {
    if (value === selectedReferenceNumber) return;
    setSelectedReferenceNumber(value);
  };

  return <Dropdown arrowVariant className={accountSelect} items={items} outline={false} onChange={handleChange} name="accountSelect" />
};

export default AccountSelect; 
