import React, { useEffect, useMemo, useState } from 'react';
import { useLocale, useOnce, useParams, useQuiqChat, useStateView } from '../../hooks';
import { accountView, defaultSpecialPlanView, paymentPlanView, shortLinkRedirectView } from '../../state/views';
import { getSpecialPaymentPlan } from '../../service/landingPage';
import { Link } from 'react-router-dom';
import { paths } from '../../routes';
import { MIXPANEL_EVENTS, PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../../constants';
import allMessages from './messages';
import {
  Button,
  CurrencyInput,
  Datepicker,
  OfferHeader,
  PaymentMethodForm,
  PaymentSummary,
  RepaymentDateDropdown,
  Spinner,
  Step,
  Stepper,
  UnverifiedPhoneLogin,
  Variant,
} from '../../components';
import {
  pplp,
  pplpContent,
  stepButtons,
  inputArea,
  inputError,
  inputWithButton,
  miniMirandaLink,
  paymentSummary
} from './styles.scss';
import confetti from 'canvas-confetti';
import { mixpanel, numDaysInMonth, redirect } from '../../utils';
import { initialState } from '../../state/store';
import Header from '../../components/Header';

const paymentPlanFromResponse = plan => {
  const lastPaymentAmount = plan.payments[plan.termsOffered - 1];

  return {
    planType: PAYMENT_PLAN_TYPES.paymentPlan,
    referenceNumber: plan.referenceNumber,
    numPayments: plan.termsOffered,
    paymentFrequency: PAYMENT_FREQUENCIES.monthly,
    paymentSchedule: [],
    proposedAmountApproved: plan.proposedAmountApproved,
    totalPaymentAmount: plan.currentBalance,
    standardPaymentAmount: plan.standardMonthlyAmount,
    lastPaymentAmount:
      plan.termsOffered > 0 && lastPaymentAmount !== plan.standardMonthlyAmount
        ? lastPaymentAmount
        : null
  };
};

const PaymentPlanOfferLandingPage = () => {
  const { formatMessage, messages } = useLocale(allMessages);

  const [ { verified } ] = useStateView(shortLinkRedirectView);
  const { referenceNumber } = useParams();
  const [ { accountholderFirstName } ] = useStateView(accountView);
  const [ paymentPlan, setPaymentPlan, clearPaymentPlan ] = useStateView(paymentPlanView);
  const [ defaultSpecialPlan, setDefaultSpecialPlan ] = useStateView(defaultSpecialPlanView);
  const [ proposedMonthlyAmount, setProposedMonthlyAmount ] = useState(null);

  if (!referenceNumber) {
    redirect(paths.specialOfferError);
  }

  const minDate = new Date();
  const startDateLimit = paymentPlan.paymentStartDateLimit || initialState.paymentPlan.paymentStartDateLimit;
  const numDays = useMemo(
    () => Math.max(numDaysInMonth() - minDate.getDate(), startDateLimit) - 1,
    [minDate.getDate(), startDateLimit]
  );
  const maxDate = useMemo(
    () => {
      const result = new Date(minDate);
      result.setDate(result.getDate() + numDays);
      return result;
    },
    [minDate, numDays]
  ); 

 
  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewPaymentPlanOfferLandingPage));

  useQuiqChat(
    {
      firstName: accountholderFirstName,
      debtId: referenceNumber,
    },
    verified
  );


  useEffect(() => {
    if (referenceNumber === defaultSpecialPlan?.referenceNumber) return;

    getSpecialPaymentPlan({
      referenceNumber,
      proposedMonthlyAmount: null,
    }).then(plan => {
      setDefaultSpecialPlan(paymentPlanFromResponse(plan));
    });
  }, [ referenceNumber ]);
  // event handlers

  const handleCreateStepOut = ({ proposedMonthlyAmount }) => {
    setProposedMonthlyAmount(null);

    let proposedAmountApproved = false;
    let eventProps = {};

    return getSpecialPaymentPlan({
      referenceNumber,
      proposedMonthlyAmount
    }).then(plan => {
      setPaymentPlan(paymentPlanFromResponse(plan));

      const {
        payments,
        standardMonthlyAmount,
        termsOffered
      } = plan;

      proposedAmountApproved = plan.proposedAmountApproved;

      eventProps = {
        lastPaymentAmount: payments.length > 0
          ? payments[payments.length - 1]
          : standardMonthlyAmount,
        standardMonthlyAmount,
        termsOffered
      };

      return proposedAmountApproved;
    }).catch(() => {
      setPaymentPlan.update({ proposedAmountApproved: false });
      return proposedAmountApproved = false;
    }).finally(() => {
      setProposedMonthlyAmount(proposedMonthlyAmount);

      mixpanel.track(
        MIXPANEL_EVENTS.proposeSpecialPaymentPlan,
        {
          currentBalance: defaultSpecialPlan.totalPaymentAmount,
          proposedAmountApproved,
          proposedMonthlyAmount,
          ...eventProps
        }
      );
    });
  };

  const handlePaymentDateStepIn = (_, stepper, step) => {
    // don't show when going back in steps
    if (step < stepper.currentStep) return;

    // put a smaller, slower-falling burst of confetti behind
    // the main one to give a little illusion of depth
    const particleCount = 128;
    const shapes = [ 'circle', 'square', 'star' ];

    confetti({
      particleCount,
      shapes,
      spread: 60,
      scalar: 0.5,
      gravity: 0.6,
    });

    confetti({
      particleCount,
      shapes,
      spread: 90,
    });
  };

  const handlePaymentDateStepOut = ({ paymentStartDate }) => {
    setPaymentPlan.update({ paymentStartDate: new Date(paymentStartDate) });
  };

  const handleClickAcceptDefault = () => {
    mixpanel.track(
      MIXPANEL_EVENTS.acceptDefaultSpecialOffer,
      { ...defaultSpecialPlan }
    );
    setPaymentPlan.update(defaultSpecialPlan);
  };

  const handleClickPayInFull = () => {
    mixpanel.track(
      MIXPANEL_EVENTS.payFullSpecialOfferBalance,
      { totalPaymentAmount: defaultSpecialPlan.totalPaymentAmount }
    );

    setPaymentPlan.update({
      planType: PAYMENT_PLAN_TYPES.payInFull,
      numPayments: 1,
      referenceNumber,
      proposedAmountApproved: true,
      standardPaymentAmount: defaultSpecialPlan.totalPaymentAmount,
      totalPaymentAmount: defaultSpecialPlan.totalPaymentAmount,
    });
  };

  const handleClickCreatePlan = () => {
    mixpanel.track(MIXPANEL_EVENTS.createCustomSpecialOffer);
  };

  // render

  const {
    initial,
    createPlan,
    paymentDate,
    paymentMethod
  } = messages.steps;

return <>
  <Header />
  <div className={pplp}>
    <UnverifiedPhoneLogin />
    <h1>{ messages.title }</h1>

      { defaultSpecialPlan.planType 
       ? <section>
          <OfferHeader currentBalance={defaultSpecialPlan.totalPaymentAmount} referenceNumber={referenceNumber} />
          { paymentPlan?.planType && <div className={paymentSummary}>
              <PaymentSummary /> </div> }
          <div className={pplpContent}>
            <Stepper name='Payment Plan Landing Page'>
              {
              /* initial step */
              }
              <Step name='Initial offer' onStepShow={clearPaymentPlan}>
                <p>{initial.planIntro}</p>
                <PaymentSummary paymentPlan={defaultSpecialPlan} inline=
                {false} />

                <p>{ formatMessage(initial.instructions, { acceptButtonText:
               initial.acceptDefaultPlan, createButtonText: initial.createPlan }) }</p>
                <div className={stepButtons}>
                <Button data-action="accept default plan" onClick=
                {handleClickAcceptDefault} data-goto-step={2}>{ initial.acceptDefaultPlan }
                </Button>
                <Button data-action="create payment plan" onClick={handleClickCreatePlan} data-next-step>{
                 initial.createPlan }</Button>
                <Button data-action="pay in full" onClick=
                {handleClickPayInFull} data-goto-step={2}>{ initial.payInFull }</Button>
                </div>
              </Step>
              {
                /* propose plan step */
              }
              <Step name="Create plan" onStepOut={handleCreateStepOut}>
                <h2>{ createPlan.title }</h2>
                <p>{ formatMessage(createPlan.instructions, { buttonText: createPlan.submit }) }</p>
                <div className={inputArea}>
                  <div className={inputWithButton}>
                  <CurrencyInput name="proposedMonthlyAmount" defaultValue={0} />
                    <Button data-action="submit proposed amount" data-next-step>{ createPlan.submit }</Button>
                  </div>
                  {proposedMonthlyAmount !== null && !paymentPlan.proposedAmountApproved && (
                    <p data-action='proposed plan error' className={inputError}>
                      {proposedMonthlyAmount >= paymentPlan.totalPaymentAmount ? createPlan.proposedAmountTooHigh : createPlan.proposedAmountTooLow}
                    </p>
                  )}
                </div>
                <div className={stepButtons}>
                <Button variant="outline" data-back-button>{ messages.goBack }</Button>
                </div>
              </Step>
              {/* setup payment date step */}
              <Step onStepIn={handlePaymentDateStepIn} onStepOut={handlePaymentDateStepOut}>
                <h2>{paymentDate.title}</h2>
                <p>{paymentDate.instructions}</p>
                <div className={inputArea}>
                  <Variant experiment='PPLP Date' variant='Datepicker'>
                    <Datepicker minDate={minDate}  name='paymentStartDate' maxDate={maxDate} />
                  </Variant>
                  <Variant experiment='PPLP Date' variant='Dropdown'>
                    <RepaymentDateDropdown compact name='paymentStartDate' />
                  </Variant>
                </div>
                <div className={stepButtons}>
                  <Button data-action='submit payment date' data-next-step>
                    {paymentDate.submit}
                  </Button>
                </div>
              </Step>
              {/* payment method step */}
              <Step>
                <h2>{paymentMethod.title}</h2>
                <PaymentMethodForm accountId={referenceNumber} onSubmit={() => redirect(paths.paymentConfirm)} />
                <div className={stepButtons}>
                  <Button variant='outline' data-back-button>
                    {messages.goBack}
                  </Button>
                </div>
              </Step>
            </Stepper>
          </div>
        </section>
     : <Spinner variant="large" /> }
     <p>{ formatMessage(messages.creditDisclosure, { receiptText: <b>{ messages.receiptText }</b> }) }</p>
     <p>{ messages.miniMiranda }</p>
     <p><Link data-mp-name="Know Your Rights" to={paths.disclosures} className={miniMirandaLink}>{ messages.knowYourRights }</Link></p>
    </div>;
  </>
};

export default PaymentPlanOfferLandingPage;