import React, { useMemo } from 'react';
import { useLocale, useStateView } from '../../hooks';
import { paymentPlanView } from '../../state/views';
import { initialState } from '../../state/store';
import { numDaysInMonth } from '../../utils';
import { ACCOUNT_DATE_FORMAT, PAYLOAD_DATE_FORMAT } from '../../constants';
import Dropdown from '../Dropdown';

const RepaymentDateDropdown = props => {
  const { formatDate, locale } = useLocale();
  const [ paymentPlan ] = useStateView(paymentPlanView);

  const items = useMemo(() => {
    const date = new Date();
    const startDateLimit = paymentPlan.paymentStartDateLimit || initialState.paymentPlan.paymentStartDateLimit;
    const numDays = Math.max(numDaysInMonth() - date.getDate(), startDateLimit) ;
    const result = [];           

    for (let i = 0; i < numDays; ++i) {
      result.push({
        label: formatDate(date, ACCOUNT_DATE_FORMAT),
        value: formatDate(date, PAYLOAD_DATE_FORMAT),
        isDefault: i === 0
      });

      date.setDate(date.getDate() + 1);
    }

    return result;
  }, [ locale, paymentPlan.paymentStartDateLimit ]);

  return <Dropdown { ...props } items={items}/>
};

export default RepaymentDateDropdown;
