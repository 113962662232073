export default {
  en: {
    welcome: 'Welcome {firstName}!',
    cta: {
      prompt: 'Start creating your payment plan or make a single payment.',
      payInFull: 'Pay in Full',
      morePaymentOptions: 'More Payment Options'
    },
    viewPaymentPlans: 'View Your Payment Plans',
    addPaymentMethodPrompt: 'Add a payment method to automatically pay down your debt.',
    addPaymentMethodCTA: 'Add Payment Method',
    accountDetails: 'Account Details',
    paymentContactNotice: 'To modify or cancel a recurring or scheduled payment, please call us at {telLink}',
    paymentContactNumber: '866-539-7525',
    creditNoticeHeading: 'Notice',
    creditNotice: 'If Spring Oaks Capital, LLC is reporting your account to a credit bureau, Spring Oaks Capital, LLC will request deletion of its tradeline from your credit report within 30 days of resolving the balance.',
  },
  es: {
    welcome: '¡Bienvenido {firstName}!',
    cta: {
      prompt: 'Comience a crear su plan de pago o realice un solo pago.',
      payInFull: 'Pague en su totalidad',
      morePaymentOptions: 'Más opciones de pago'
    },
    viewPaymentPlans: 'Ver sus planes de pago',
    addPaymentMethodPrompt: 'Agregue un método de pago para pagar automáticamente su deuda.',
    addPaymentMethodCTA: 'Agregue un método de pago',
    accountDetails: 'Detalles de la cuenta',
    paymentContactNotice: 'Para modificar o cancelar un pago recurrente o programado, llámenos al {telLink}',
    paymentContactNumber: '866-539-7525',
    creditNoticeHeading: 'Aviso',
    creditNotice: 'Si Spring Oaks Capital, LLC reporta esta cuenta a una agencia de informes de crédito en el transcurso de 30 días a partir de la recepción del pago final que resuelve esta cuenta, Spring Oaks Capital, LLC, solicitará la eliminación de su cuenta de crédito de su informe de crédito.'
  }
};
