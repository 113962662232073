export const ACCOUNT_DATE_FORMAT = {
  month: 'short',
  day: 'numeric',
  year: 'numeric'
};

export const PAYMENT_INFO_FORMAT = {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
};

export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';
