export default {
  home: '/',
  login: '/login',

  version: '/version',
  notFound: '/not-found',

  selectAccount: '/select-account',
  accountError: '/account-error',
  documents: '/documents',
  paymentHistory: '/payment-history',
  
  paymentPlan: '/payment-plan',
  paymentPartial: '/payment-plan/partial',
  paymentPlanCreate: '/payment-plan/create',
  paymentPlanSetup: '/payment-plan/setup',
  paymentPlanError: '/payment-plan/error',
  paymentConfirm: '/payment-confirm',
  paymentSuccess: '/payment-success',
    
  shortLink: '/:ref/:shortUrlPath',
  
  settlementOfferLandingPage: '/special-offer/user/:referenceNumber',
  paymentPlanOfferLandingPage: '/payment-plan/user/:referenceNumber',
  specialOfferError: '/special-offer/error',

  disclosures: '/disclosures',
  uniqueDisclosures: '/disclosures/:referenceNumber',
  homeDisclosures: '/home-disclosures',
};
