import React from 'react';
import { Link } from 'react-router-dom';

import headerLogoSrc from '../../static/images/icons/logoIcon.svg';
import headerSmallLogoSrc from '../../static/images/icons/logoMinIcon.svg';
import LanguageSelect from '../LanguageSelect';
import { paths } from '../../routes';

import { header, logo, menu } from './styles.scss';

import menuSrc from '../../static/images/icons/menu.svg';
import Button from '../Button';
import { useStateView } from '../../hooks';
import { menuToggledView } from '../../state/views';

const Header = () => {
  const [ menuToggled, setMenuToggled ] = useStateView(menuToggledView);

  return (
    <>
      <div className={header}>
        <Button id='menu' onClick={() => setMenuToggled(!menuToggled)}>
          <img src={menuSrc} className={menu} />
        </Button>
        <Link data-mp-name="Header Logo" to={paths.home}>
          <img src={headerLogoSrc} className={logo} />
        </Link>
        <LanguageSelect />
      </div>
    </>
  );
}

export default Header;