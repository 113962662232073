import React, { useEffect, useState } from 'react';
import { useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import { accountView, activePaymentPlansView, paymentPlanView } from '../../state/views';
import { getPaymentPlans } from '../../service/paymentPlan';
import { formatCurrency, mixpanel, redirect } from '../../utils';

import allMessages from './messages';

import { Button, ProgressBar, Spinner } from '../../components';

import { addPaymentMethod,
  nextPayments,
  paymentPlansEmpty,
  progressBar,
  repaymentOption,
  wrapper,
  stepper,
  title,
  disclosureMessage
} from './styles.scss';
import EmptyPage from '../../components/EmptyPage/index.js';
import { MIXPANEL_EVENTS, PAYMENT_PLAN_TYPES } from '../../constants';
import paths from '../../routes/paths.js';
import { PartialPaymentIcon, PayInFullIcon, PaymentPlanIcon } from '../../components/icons/index.js';
import CreditDisclosure from '../../components/CreditDisclosure/index.js';

const PaymentPlanPage = () => {
  const { formatDate, messages } = useLocale(allMessages);
  const [ { accountId: referenceNumber, remainingBalance } ] = useStateView(accountView);
  const [ activePaymentPlans, setActivePaymentPlans ] = useStateView(activePaymentPlansView);
  const [ , setPaymentPlan, clearPaymentPlan ] = useStateView(paymentPlanView);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewPaymentPlansPage));

  useQuiqChat({ debtId: referenceNumber });

  useEffect(() => {
    if (isLoaded) return;
    setIsLoading(true);

    getPaymentPlans(referenceNumber)
      .then(data => {
        setActivePaymentPlans(data);
        setIsLoading(false);
        setIsLoaded(true);
      });
  }, []);

  const paymentPlans = activePaymentPlans?.paymentPlans;

  const handlePayInFull = () => {
    clearPaymentPlan();
    mixpanel.track(MIXPANEL_EVENTS.selectPayInFullOption);
    setPaymentPlan.update({
      planType: PAYMENT_PLAN_TYPES.payInFull,
      numPayments: 1,
      standardPaymentAmount: remainingBalance,
      totalPaymentAmount: remainingBalance
    });
    redirect(paths.paymentPlanSetup);
  };
  
  const handleCreatePlan = () => {
    clearPaymentPlan();
    mixpanel.track(MIXPANEL_EVENTS.selectCreatePaymentPlanOption);
    setPaymentPlan.update({
      planType: PAYMENT_PLAN_TYPES.paymentPlan
    });
    redirect(paths.paymentPlanCreate);
  };
  
  const handlePartialPayment = () => {
    clearPaymentPlan();
    mixpanel.track(MIXPANEL_EVENTS.selectPartialPaymentOption);
    setPaymentPlan.update({
      planType: PAYMENT_PLAN_TYPES.paymentPartial,
      numPayments: 1
    });
    redirect(paths.paymentPartial);
  };

  return <>
    {isLoading ? <Spinner /> : (
      <div className={wrapper}>
        <div className={stepper} />
        <div className={title}>
          <h1>{messages.title}</h1>
          <p>{messages.description}</p>
        </div>
        {(!paymentPlans || paymentPlans.length <= 0) && (
          <div className={paymentPlansEmpty}>
            <a
              onClick={handlePayInFull}
              data-action="select pay in full"
              className={repaymentOption}
            >
              <PayInFullIcon alt="Hand holding a dollar bill" />
              <h4>{ messages.payInFull }</h4>
              <p>{messages.payInFullSubtitle}</p>
            </a>
            <a
              onClick={handleCreatePlan}
              data-action="select create plan"
              className={repaymentOption}
            >
              <PaymentPlanIcon alt="Schedule" />
              <h4>{ messages.createPlan }</h4>
              <p>{messages.createPlanSubtitle}</p>
            </a>
            <a
              onClick={handlePartialPayment}
              data-action="select partial payment"
              className={repaymentOption}
            >
              <PartialPaymentIcon alt="Hand offering money" />
              <h4>{ messages.partialPayment }</h4>
              <p>{messages.partialPaymentSubtitle}</p>
            </a>
          </div>
        )}
        {paymentPlans instanceof Array && paymentPlans.length > 0 && (
          <div>
            {paymentPlans.map(plan => {
              const {
                planId,
                isPromise,
                paymentSchedule,
                originalBalance,
                remainingBalance
              } = plan;

              const alreadyPaid = originalBalance - remainingBalance;

              return (
                <section key={`payment-plan-${referenceNumber}-${planId}`}>
                  <h3>#{planId}</h3>
                  <hr />
                  <p>
                    <strong>{ messages.remainingBalance }: </strong>
                    { formatCurrency(remainingBalance) }
                  </p>
                  <p>
                    <strong>{ messages.installments }: </strong>
                    { paymentSchedule.length }
                  </p>
                  { isPromise && <div className={addPaymentMethod}><Button href={paths.paymentPlanSetup}>{ messages.addPaymentMethodCTA }</Button></div> }
                  <hr />
                  <div className={progressBar}>
                    <ProgressBar value={25} />
                    <p>
                      { messages.alreadyPaid }
                      <strong> { formatCurrency(alreadyPaid) } </strong>
                      { messages.of }
                      <strong> { formatCurrency(originalBalance) } </strong>
                    </p>
                  </div>
                  <hr />
                  <p><strong>{ messages.nextPayments }</strong></p>
                  <div className={nextPayments}>
                    { paymentSchedule.map(p => {
                        if (p.paymentStatus !== 'PENDING') {
                          return null;
                        }

                        const date = new Date(p.paymentDate);

                        return <div>
                          <p>{ formatDate(date, { day: 'numeric' }) }</p>
                          <p>{ formatDate(date, { month: 'short' }) }</p>
                          <p><strong>{ formatCurrency(p.paymentAmount) }</strong></p>
                        </div>;
                      }) }
                  </div>
                </section>
              )
            })}
          </div>
        )}
        <CreditDisclosure allMessages={allMessages} />
      </div>
    )}
  </>;
};

export default PaymentPlanPage;
